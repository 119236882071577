import store from "../_store/index";
import apiService from "./api.service";


export const authService = {
    login,
    logout,
    forgotPassword,
    changePasswordByToken
};

/**
 *
 * @param userEmail
 * @param userPass
 * @returns {Promise<AxiosResponse<any>>}
 */
function login(userEmail, userPass) {
    let data = new FormData();
    data.append('user_email', userEmail);
    data.append('user_pass', userPass);

    return apiService.post('/auth/login', data).then(response => {
        if (response.data.JWT) {
            store.commit("common/setJWT", response.data.JWT);
            apiService.init();
        }

        return response.data;
    });
}

function logout() {
    localStorage.removeItem('JWT');
}

/**
 *
 * @param userEmail
 * @param userPass
 * @param userPassConfirm
 * @returns {Promise<AxiosResponse<any>>}
 */
function registration(userEmail, userPass, userPassConfirm) {
    let data = new FormData();
    data.append('user_email', userEmail);
    data.append('user_pass', userPass);
    data.append('user_pass_confirm', userPassConfirm);

    return apiService.post('/users/create', data).then(response => {
        return response.data;
    });
}

/**
 *
 * @param userEmail
 * @returns {Promise<AxiosResponse<any>>}
 */
function forgotPassword(userEmail, referrer) {
    let data = new FormData();
    data.append('user_email', userEmail);
    data.append('referrer', referrer);

    return apiService.post('/auth/forgot_password', data).then(response => {
        return response.data;
    });
}

/**
 *
 * @param token
 * @param userPass
 * @param userPassConfirm
 * @returns {Promise<AxiosResponse<any>>}
 */
function changePasswordByToken(token, userPass, userPassConfirm) {
    let data = new FormData();
    data.append('token', token);
    data.append('user_pass', userPass);
    data.append('user_pass_confirm', userPassConfirm);

    return apiService.post('/auth/change_password_by_token', data).then(response => {
        return response.data;
    });
}