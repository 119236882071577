import {commentsService} from "../_services/comments.service";

export const comments = {
    namespaced: true,
    state: () => ({
        comments: [],
    }),
    actions: {
        async fetchComments({commit}) {
            const comments_ = await commentsService.fetchComments();
            commit("setComments", comments_.data);
        }
    },
    mutations: {
        setComments(state, data) {
            state.comments = data;
        }
    },
    getters: {
        getComments(state) {
            return state.comments;
        }
    }
}