import {createStore} from 'vuex'
import {projects} from './projects.module';
import {tasks} from './tasks.module';
import {teams} from './teams.module';
import {teamsIntegration} from './teams-integration.module';
import {user} from './user.module';
import {tasksFilters} from './tasks-filters.module';
import {projectsFilters} from './projects-filters.module';
import {tasksRightSidebar} from "./tasks-right-sidebar";
import {comments} from "./comments.module";
import {common} from "./common.module";
import {notifications} from "./notifications";

export default createStore({
    modules: {
        projects,
        tasks,
        teams,
        teamsIntegration,
        user,
        tasksFilters,
        projectsFilters,
        tasksRightSidebar,
        comments,
        common,
        notifications,
    }
});
