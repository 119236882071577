import {createRouter, createWebHistory} from 'vue-router';
import config from 'config';
import store from '../_store/index';

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('../views/home/Home.vue'),
    },
    {
        path: '/:teamId',
        name: 'Team',
        component: () => import('../views/tasks/Tasks.vue'),
        children: [
            {
                path: ':projectPrefix',
                name: 'Project',
                component: () => import('../views/tasks/Tasks.vue'),
            },
            {
                path: ':projectPrefix-:taskId',
                name: 'Task',
                component: () => import('../views/tasks/Tasks.vue'),
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/Login.vue'),
    },
    {
        path: '/registration',
        name: 'Registration',
        component: () => import('../views/registration/Registration.vue'),
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: () => import('../views/forgot-password/ForgotPassword.vue'),
    },
    // otherwise redirect to home
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

router.beforeEach((to, from, next) => {
    (async () => {
        const JWT = store.getters['common/getJWT'];

        if (!store.getters['user/getInitialSettings'] && JWT) {
            await store.dispatch('user/fetchInitialSettings');
        }

        if (to.name === 'Index' && JWT || to.name === 'Team' && JWT) {
            return next({name: 'Project', params: {teamId: store.getters['user/getInitialSettings'].team.team_id, projectPrefix: store.getters['user/getInitialSettings'].project.project_prefix}});
        }

        const publicPages = config.publicPages;
        const authRequired = !publicPages.includes(to.path);
        if (authRequired && !JWT) {
            return next({name: 'Index'});
        }
        return next();
    })();
});
