import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './_store'
import apiService from "./_services/api.service";
import ReadMore from 'vue-read-more';



apiService.init();
createApp(App)
    .use(store)
    .use(router)
    .use(ReadMore)
    .mount('#app');