export const tasksFilters = {
    namespaced: true,
    state: () => ({
        filterByStatus: 1
    }),
    actions: {},
    mutations: {
        setFilterByStatus(state, payload) {
            state.filterByStatus = payload;
        }
    },
    getters: {
        getFilterByStatus(state) {
            return state.filterByStatus;
        }
    }
}
