import axios from "axios";
import config from 'config';
import {authHeader} from "../_helpers";
import router from "../router";

const apiService = {
    axios: null,

    init() {
        this.axios = axios.create({
            baseURL: config.apiUrl,
            headers: authHeader(),
            withCredentials: false
        });
    },

    get(url, params) {
        return this.axios.get(
            url,
            {
                params: params
            }
        ).catch(error => {
            return processError(error);
        })
    },
    post(url, data, config_ = {}) {
        return this.axios.post(
            url,
            data,
            config_
        ).catch(error => {
            return processError(error);
        })
    },
    put(url, data) {
        return this.axios.put(
            url,
            data
        ).catch(error => {
            return processError(error);
        })
    },
    delete(url, data) {
        return this.axios.delete(
            url,
            {
                data: data
            }
        ).catch(error => {
            return processError(error);
        })
    }
}

export default apiService;

function processError(error) {
    if (error.response && error.response.status === 401) {
        return router.push("/login");
    } else {
        return Promise.reject(error);
    }
}