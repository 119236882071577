export const projectsFilters = {
    namespaced: true,
    state: () => ({
        search: ""
    }),
    getters: {
        getSearch(state) {
            return state.search;
        },
    }
}
