import store from '../_store/index';
import config from 'config';
import router from '../router/index'

export const WSEventService = {
    subscribeEvents
};

let ws = null;

function subscribeEvents() {
    let currentRoute = router.currentRoute.value.path;
    let publicPages = config.publicPages;
    if (publicPages.includes(currentRoute)) {
        if (ws !== null) {
            ws.close();
        }
        return;
    }
    let subscribeEvents_ = Object.keys(eventsDispatchers);
    let initialSettings = store.getters["user/getInitialSettings"];
    let userId = initialSettings.user.user_id;
    let teamId = initialSettings.team.team_id;
    ws = new WebSocket(`${config.WSHost}/?user_id=${userId}`);
    ws.onopen = () => {
        let data = {
            action: "events_subscribe",
            user_id: userId,
            subscribe_data: {
                team_id: teamId,
                events: subscribeEvents_
            }
        }
        ws.send(JSON.stringify(data));
    }
    ws.onmessage = data => {
        data = JSON.parse(data.data);
        if (
            (!data || !data.action || !data.event_type)
            || data.action !== "event_notify"
        ) {
            return;
        }
        eventsDispatchers[data.event_type]();
    }
}

const eventsDispatchers = {
    "event_task_create": () => {
        store.dispatch("projects/fetchProjects");
        store.dispatch("tasks/fetchTasks");
    },
    "event_project_create": () => store.dispatch("projects/fetchProjects")
};