export const notifications = {
    namespaced: true,
    state: () => ({
        notifications: [],
    }),
    actions: {},
    mutations: {
        setNotification(state, notification) {
            state.notifications.push(notification);
        },
        removeNotifications(state) {
            state.notifications = [];
        },
    },
    getters: {
        getNotifications(state) {
            return state.notifications
        },
    }
}
