import {initialSettingsService} from '../_services/initial-settings.service';
import apiService from '../_services/api.service';
import {authService} from '../_services';

export const user = {
    namespaced: true,

    state: () => ({
        initialSettings: defaultInitialSettings(),
    }),

    actions: {
        async fetchInitialSettings({commit, rootState}) {
            const JWT = rootState.common.JWT;
            if (JWT) {
                const initialSettings_ = await initialSettingsService.fetchSettings();
                commit('setInitialSettings', initialSettings_.data);
            }
        },

        async login({commit}, payload) {
            return new Promise((resolve, reject) => {
                authService.login(payload.userEmail, payload.userPass).then(resp => {
                    resolve(resp);
                }, error => {
                    reject(error);
                });
            });
        },

        async register({commit}, payload) {
            let form = new FormData;
            form.append('user_email', payload.userEmail);
            form.append('referrer', payload.referrer);
            if (payload.invite_token) {
                form.append('invite_token', payload.invite_token);
            }

            return new Promise((resolve, reject) => {
                apiService.post('users/one_click_register', form).then(resp => {
                    resolve(resp);
                    localStorage.setItem('firstEntry', 'true');
                }, error => {
                    reject(error);
                });
            });
        },
    },

    mutations: {
        setInitialSettings(state, data) {
            localStorage.setItem('initialSettings', JSON.stringify(data));
            state.initialSettings = data;
        },
    },

    getters: {
        getInitialSettings(state) {
            return state.initialSettings;
        },
        userIsOwner: (state) => state.initialSettings &&
            state.initialSettings.user.role_label &&
            state.initialSettings.user.role_label === 'Owner',
        userIsLogged: (state) => state.initialSettings &&
            state.initialSettings.hasOwnProperty('user') &&
            state.initialSettings.user.hasOwnProperty('user_id') &&
            !!state.initialSettings.user.user_id,
    },
};

function defaultInitialSettings() {
    try {
        return JSON.parse(localStorage.getItem('initialSettings'));
    } catch (e) {
        return null;
    }
}
