import {projectsService} from "../_services/projects.service";

export const projects = {
    namespaced: true,
    state: () => ({
        projects: {},
        remoteProjects: [],
        activeProjectId: null
    }),
    actions: {
        async fetchProjects({commit}) {
            const projects_ = await projectsService.fetchProjects();
            commit('setProjects', projects_.data);
        },
        async fetchRemoteProjects({commit}) {
            const projects_ = await projectsService.fetchRemoteProjects();
            commit('setRemoteProjects', projects_.data);
        },
        async updateProject({commit}, data) {
            const response = await projectsService.updateProject(data);
            return response.data;
        }
    },
    mutations: {
        setProjects(state, data) {
            state.projects = data
        },
        setActiveProjectId(state, data) {
            state.activeProjectId = data;
        },
        setRemoteProjects(state, data) {
            state.remoteProjects = data;
        }
    },
    getters: {
        getProjects(state) {
            return state.projects
        },
        getActiveProject(state) {
            if (!state.projects.projects) {
                return null;
            }
            const index = state.projects.projects.findIndex(project => project.project_id === state.activeProjectId);
            return index !== -1 ? state.projects.projects[index] : null;
        },
        getRemoteProjects(state) {
            return state.remoteProjects;
        },
        getProjectByPrefix: (state) => (prefix) => {
            if (!state.projects.projects) {
                return null;
            }
            return state.projects.projects.find(el => el.project_prefix === prefix);
        }
    }
}
