export const common = {
    namespaced: true,
    state: () => ({
        addProjectModalIsActive: false,
        JWT: localStorage.getItem('JWT'),
        modalProfile: {
            active: false,
            closable: true,
        },
    }),
    mutations: {
        setAddProjectModalState(state, data) {
            state.addProjectModalIsActive = data;
        },
        setJWT(state, data) {
            localStorage.setItem('JWT', data);
            state.JWT = data;
        },
        setModalProfile(state, data) {
            state.modalProfile = data;
        },
    },
    getters: {
        getAddProjectModalState(state) {
            return state.addProjectModalIsActive;
        },
        getJWT(state) {
            return state.JWT;
        },
        getModalProfile(state) {
            return state.modalProfile;
        }
    }
}