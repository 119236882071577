import store from '../_store/index';
import apiService from "./api.service";


export const commentsService = {
    fetchComments
};

function fetchComments() {
    return apiService.get('/comments/index', queryBuilder());
}

function queryBuilder() {
    let query = {};
    query['task_id'] = store.getters["tasks/getActiveTask"].id;

    return query;
}