export const tasksRightSidebar = {
    namespaced: true,
    state: () => ({
        activeReplyFormId: 0,
        fetchComments: false
    }),
    actions: {

    },
    mutations: {
        setActiveReplyForm(state, value) {
            state.activeReplyFormId = value;
        },
        setFetchComments(state, value) {
            state.fetchComments = value;
        },
    },
    getters: {
        getActiveReplyForm(state) {
            return state.activeReplyFormId
        },
        getFetchComments(state) {
            return state.fetchComments
        }
    }
}
