import store from '../_store/index';
import apiService from "./api.service";


export const projectsService = {
    fetchProjects,
    createProject,
    fetchRemoteProjects,
    updateProject,
};

function fetchProjects() {
    return apiService.get('/projects/index', queryBuilder());
}

function fetchRemoteProjects() {
    const initialSettings = store.getters['user/getInitialSettings'];
    const query = {
        team_id: initialSettings.team.team_id
    };
    return apiService.get('team-integrations/get_spaces', query);
}

function updateProject(data) {
    const formData = new FormData;
    const initialSettings = store.getters['user/getInitialSettings'];

    formData.append('project_id', data.project_id);

    if (data.remote_id) {
        formData.append('remote_id', data.remote_id);
    }

    formData.append('team_id', initialSettings.team.team_id);
    if (data.project_name) {
        formData.append('project_name', data.project_name);
    }

    if (data.project_prefix) {
        formData.append('project_prefix', data.project_prefix);
    }

    return apiService.post('projects/update', formData);
}

function createProject(projectName = '', domain = '', teamId = 0, projectPrefix = '') {
    const formData = new FormData;

    formData.append("project_name", projectName);
    formData.append("project_prefix", projectPrefix);
    formData.append("domain", domain);
    formData.append("team_id", teamId + "");

    return apiService.post("/projects/create", formData);
}

function queryBuilder() {
    let query = {};

    const activeTeamId = store.getters["teams/getActiveTeamId"];
    const search = store.getters["projectsFilters/getSearch"];

    if (activeTeamId) {
        query['team_id'] = activeTeamId;
    }
    if (search.length) {
        query['project_name'] = search;
    }

    return query;
}