import {tasksService} from "../_services/tasks.service";
import apiService from "../_services/api.service";

export const tasks = {
    namespaced: true,
    state: () => ({
        tasks: {},
        activeTaskId: null,
        isFetching: false,
        isFetched: false,
        singleTaskIsNotFound: false,
    }),
    actions: {
        async fetchTasks({commit}) {
            commit('setFetched', false);
            commit('setFetching', true);
            let tasks_ = await tasksService.fetchTasks();
            commit('setTasks', tasks_.data);
            commit('setFetching', false);
            commit('setFetched', true);
        },

        async deleteTask({commit}, payload) {
            commit('deleteTask', payload);
            apiService.delete(`tasks/delete/${payload.id}`);
        },
        async changeStatus({commit}, {task, status}) {
            commit("deleteTask", task);
            const formData = new FormData();
            formData.append("task_id", task.id);
            formData.append("status", status + "");
            formData.append("__method__", "PUT");
            apiService.post("tasks/update", formData);
        }
    },
    mutations: {
        setSingleTaskIsNotFound(state, payload) {
            state.singleTaskIsNotFound = payload
        },
        setFetching(state, payload) {
            state.isFetching = payload
        },
        setFetched(state, payload) {
            state.isFetched = payload
        },
        setTasks(state, payload) {
            state.tasks = payload
        },
        setActiveTaskId(state, payload) {
            state.activeTaskId = payload
        },
        switchTask(state, data) {
            const activeTask = this.getters['tasks/getActiveTask'];
            let activeTaskId = null;
            if (activeTask) {
                activeTaskId = activeTask.id;
            }
            const index = state.tasks.tasks.findIndex(task => task.id === activeTaskId);
            if (!state.tasks.tasks) {
                return;
            }
            if (index === -1) {
                state.activeTaskId = state.tasks.tasks[0].id;
            } else if (state.tasks.tasks[index + (data === 'next' ? 1 : -1)]) {
                state.activeTaskId = state.tasks.tasks[index + (data === 'next' ? 1 : -1)].id;
            }
        },
        deleteTask(state, payload) {
            const index = state.tasks.tasks.findIndex(task => task.id === payload.id);
            if (index !== -1) {
                state.tasks.tasks.splice(index, 1);
            }
        },
    },
    getters: {
        getSingleTaskIsNotFound(state) {
            return state.singleTaskIsNotFound
        },
        getTasks(state) {
            return state.tasks
        },
        isFetching(state) {
            return state.isFetching
        },
        isFetched(state) {
            return state.isFetched
        },
        getActiveTask(state) {
            if (!state.tasks.tasks) {
                return null;
            }
            const index = state.tasks.tasks.findIndex(task => task.id === state.activeTaskId);
            return index !== -1 ? state.tasks.tasks[index] : null;
        }
    }
}
