<template>
  <div class="notifications" v-if="getNotifs">
    <div v-for="notification in getNotifs" class="notifications__item" :class="`notifications__item--${ notification.type }`">
      <div class="notifications__title" :class="`notifications__title--${ notification.type }`">
        {{ notification.title }}
      </div>
      <div class="notifications__desc">{{ notification.desc }}</div>
      <div class="notifications__close" v-show="closable"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'Notifications',
  data: () => {
    return {
      notifications: [],
      closable: false,
    };
  },
  methods: {
    ...mapMutations(
      {
        setNotification: 'notifications/setNotification',
      }
    ),
  },
  computed: {
    ...mapGetters(
      {
        getInitialSettings: 'user/getInitialSettings',
        getNotifications: 'notifications/getNotifications',
      },
    ),
    getNotifs() {
      return this.getNotifications;
    },
  },
};
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>