import {teamsService} from "../_services/teams.service";
import {authService} from "@/_services";
import apiService from "@/_services/api.service";

export const teams = {
    namespaced: true,
    state: () => ({
        teams: null,
        activeTeamId: null,
    }),
    actions: {
        async fetchTeams({commit}) {
            const teams_ = await teamsService.fetchTeams();
            commit('setTeams', teams_.data);
        },
        async updateTeam({commit}, payload) {
            return new Promise((resolve, reject) => {
                apiService.post( '/teams/update', payload ).then(resp => {
                    resolve(resp);
                }, error => {
                    reject(error);
                });
            });
        },
    },
    mutations: {
        setTeams(state, data) {
            state.teams = data;
        },
        setActiveTeamId(state, data) {
            state.activeTeamId = data;
        }
    },
    getters: {
        getTeams(state) {
            return state.teams;
        },
        getActiveTeamId(state) {
            return state.activeTeamId;
        },
        getActiveTeam(state) {
            if (!state.teams) {
                return null;
            }
            const index = state.teams.findIndex(team => team.team_id === state.activeTeamId);
            return index !== -1 ? state.teams[index] : null;
        }
    }
}
