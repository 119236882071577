<template>
  <div>
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet">
    <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet">
    <Notifications/>
    <router-view/>
  </div>
</template>

<script>
import Notifications from "./components/notifications/Notifications";
import {WSEventService} from "./_services/ws-events.service";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'App',
  components: {
    Notifications,
  },
  async mounted() {
    await this.fetchInitialSettings();
  },
  methods: {
    ...mapActions(
      {
        'fetchInitialSettings': 'user/fetchInitialSettings'
      }
    ),
  },
  watch: {
    $route() {
      WSEventService.subscribeEvents();
    }
  }
}
</script>

<style lang="postcss">
@import "styles/reset.css";
@import "styles/variables.css";
@import "styles/grid.css";
@import "styles/framework.css";
@import "styles/app.css";
</style>
