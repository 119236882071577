import apiService from "@/_services/api.service";

export const teamsIntegration = {
    namespaced: true,
    actions: {
        async fetchIntegration( {commit}, payload ) {
            const data = new FormData();
                  data.append( 'team_id', payload.team_id );
                  data.append( 'app_id', payload.integrations.clickup.app_id );
                  data.append( 'code', payload.code || '' );
            return new Promise((resolve, reject) => {
                apiService.post( '/team-integrations/create_or_update', data ).then(resp => {
                    resolve(resp);
                }, error => {
                    reject(error);
                });
            });
        },
        async deleteIntegration( {commit}, team_id ) {
            return apiService.delete(`/team-integrations/delete/${team_id}`);
        },
    },
}
