import store from '../_store/index';
import apiService from "./api.service";

export const tasksService = {
    fetchTasks,
};

function fetchTasks() {
    return apiService.get("/tasks/index", queryBuilder());
}

function queryBuilder() {
    let query = {};

    const activeTeamId = store.getters["teams/getActiveTeamId"];
    const project = store.getters["projects/getActiveProject"];
    const status = store.getters["tasksFilters/getFilterByStatus"];

    if (activeTeamId) {
        query["team_id"] = activeTeamId;
    }

    if (project) {
        query["project_ids"] = [project.project_id];
    }

    if (status) {
        query["status_ids"] = [status];
    }

    return query;
}